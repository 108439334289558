type StatusColorMapping = {
  [key: string]: { textColor: string; backgroundColor: string };
};
export const statusColorMapping: StatusColorMapping = {
  "In-Review": { textColor: "#be5d24", backgroundColor: "#fffaea" },
  PreApproved: { textColor: "#175cd3", backgroundColor: "#eff8ff" },
  PreRejected: { textColor: "#c7574f", backgroundColor: "#fef3f2" },
  Approved: { textColor: "#108253", backgroundColor: "#ecfef3" },
  Rejected: { textColor: "#c7574f", backgroundColor: "#fef3f2" },
  Default: { textColor: "#175cd3", backgroundColor: "#eff8ff" },
};
