// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  .layout {
  display: block;
  gap: 0px;
  height: 100%;
  max-width: 100%;

}  */

/* .layout .content {
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 100px;
  padding-left: 100px;
} */

.breadcrumb {
  position: fixed;
  z-index: 9999;
  display: flex;
  gap: 24px;
  list-style-type: none;
  padding: 3px 16px;
  margin: 0px;
  width: 100%;
  background-color: black;
  font-size: large;
}

.breadcrumb a{
  color: white;
  font-size: large;
  text-decoration: none;
}

.breadcrumb li p{
  color: white;
  font-size: large;
}

/* .container{
  display: flex;
  position: relative;
} */

.menu {
  padding: 36px 0px;
}

.menu a {
  color: black;
  text-decoration: none;
}

.menu ul {
  padding-left: 0px;
  list-style: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;IAMI;;AAEJ;;;;;;GAMG;;AAEH;EACE,eAAe;EACf,aAAa;EACb,aAAa;EACb,SAAS;EACT,qBAAqB;EACrB,iBAAiB;EACjB,WAAW;EACX,WAAW;EACX,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;;GAGG;;AAEH;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["/*  .layout {\n  display: block;\n  gap: 0px;\n  height: 100%;\n  max-width: 100%;\n\n}  */\n\n/* .layout .content {\n  width: 100%;\n  flex-direction: column;\n  flex-grow: 1;\n  padding-top: 100px;\n  padding-left: 100px;\n} */\n\n.breadcrumb {\n  position: fixed;\n  z-index: 9999;\n  display: flex;\n  gap: 24px;\n  list-style-type: none;\n  padding: 3px 16px;\n  margin: 0px;\n  width: 100%;\n  background-color: black;\n  font-size: large;\n}\n\n.breadcrumb a{\n  color: white;\n  font-size: large;\n  text-decoration: none;\n}\n\n.breadcrumb li p{\n  color: white;\n  font-size: large;\n}\n\n/* .container{\n  display: flex;\n  position: relative;\n} */\n\n.menu {\n  padding: 36px 0px;\n}\n\n.menu a {\n  color: black;\n  text-decoration: none;\n}\n\n.menu ul {\n  padding-left: 0px;\n  list-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
