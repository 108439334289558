import { Chip, ChipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StatusChipProps extends ChipProps {
    textcolor?: string;
    backgroundcolor?: string;
}
const StatusChip = styled(Chip)<StatusChipProps>(({textcolor, backgroundcolor, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: textcolor,
    backgroundColor: backgroundcolor,
    '& .MuiChip-label': {
        fontWeight: 'Bold',
    },
}));

export default StatusChip;