import { useBreadcrumb } from "@refinedev/core";
import styled from "styled-components";
import Box from "lib/ui/mui/Box";
import Typography from "lib/ui/mui/Typography";
import Chip from "lib/ui/mui/Chip";
import Link from "lib/ui/mui/Link";
import Avatar from "@mui/material/Avatar";
import TestImage from "assets/icons/TestIcon.png";

const HeaderBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: "0",
  top: "0",
  width: "100%",
  background: theme.palette.primary.main,
  zIndex: "999",
  boxSizing: "border-box",
  "&.drop-shadow": {
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2))",
    transition: "all 0.5s ease",
  },
}));

const HeaderGroup = styled(Box)`
  display: flex;
  align-items: center;
`;

const LogoBox = styled(Box)`
  display: inline-block;
  float: left;
  width: 25%;
`;

const LogoLink = styled(Link)`
  display: inline-block;
  width: auto;
  padding: 5px 0px 5px 100px;
`;

const Logo = styled.img`
  display: block;
  width: 100%;
  max-height: 60px;
`;

const NavBox = styled(Box)`
  display: inline-block;
  float: right;
  width: 75%;
  padding-right: 100px;
  text-align: right;
`;

const NavigationGroup = styled(Box)`
  display: inline-block;
  text-align: center;
  padding: 0 14px;
  vertical-align: middle;
  span {
    display: inline-block;
    text-decoration: none;
    padding: 0px 0;
    font-size: 18px;
  }
`;

const LabelChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.chip.background,
  color: theme.palette.chip.label,
  borderRadius: "5px",
  padding: "5px 16px",
  fontWeight: "normal",
}));

export const Header: React.FC = () => {
  const HeaderLogoURL = process.env.REACT_APP_HEADER_LOGO_URL;
  const logoSrc = HeaderLogoURL ? HeaderLogoURL : TestImage;

  return (
    <HeaderBox>
      <HeaderGroup>
        <LogoBox>
          <LogoLink href={"/"}>
            <Logo src={logoSrc} />
          </LogoLink>
        </LogoBox>
        <NavBox>
          <NavigationGroup key={"ng-1"}>
            <Typography component="span">
              <LabelChip label="Receipts" variant="filled" />
            </Typography>
          </NavigationGroup>
          <NavigationGroup key={"ng-2"}>
            <Typography component="span">
              <Avatar sx={{ width: 40, height: 40 }} />
            </Typography>
          </NavigationGroup>
        </NavBox>
      </HeaderGroup>
    </HeaderBox>
  );
};
