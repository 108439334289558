import React from 'react'
import styled from 'styled-components'
import MuiLink from '@mui/material/Link';
import { palette, typography } from '@mui/system';

const Link = styled(({  ...props }) => (
  <MuiLink {...props} />
))`
  ${palette}
  ${typography}
`

Link.defaultProps = {
  color:'primary',
  underline: 'none',
  variant: 'body1',
}
export default Link
