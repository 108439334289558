import React, { useState, ReactElement, useEffect } from "react";
import { Edit, useAutocomplete } from "@refinedev/mui";
import {
  TextField,
  Autocomplete,
  Typography,
  InputLabel,
  Table,
  TableBody,
  TableRow,
  TableCell,
  InputAdornment,
  BoxProps,
  Stack,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm } from "@refinedev/react-hook-form";
import {
  IResourceComponentsProps,
  useWarnAboutChange,
  useGo,
  useList,
  HttpError,
} from "@refinedev/core";
import { Controller, FieldValues } from "react-hook-form";
import styled from "@emotion/styled";
import Box from "lib/ui/mui/Box";
import Button from "lib/ui/mui/Button";
import Card from "lib/ui/mui/Card";
import StatusChip from "lib/ui/core/StatusChip";
import { EditLayout } from "components/editLayout";
import { CustomTable } from "components/table";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import { statusColorMapping } from "constant/color-mapping";

const css = `
  .react-transform-wrapper, .react-transform-component {
    width: 100% !important;
  }
`;

const TitleBox = styled(Box)(({ theme }) => ({
  paddingBottom: "30px",
}));

const Content = styled(Card)(({ theme }) => ({}));

const ReceiptPhotoContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  border: "1px solid lightgray",
}));

interface ReceiptPhotoBoxProps extends BoxProps {
  url?: string;
}
const ReceiptPhotoBox = styled(Box)<ReceiptPhotoBoxProps>(({ url, theme }) => ({
  //padding: "16px",
  background: "#D8E4E4",
  //width: "auto",
  //fontSize: "50px",
  //fontWeight: "bold",
  //display: "flex",
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${url})`,
  cursor: "pointer",
  //flex: 1,
}));

const ReceiptDetailsContainer = styled(Box)(({ theme }) => ({
  //component: "form",
  //autoComplete: "off",
  //display: "flex",
  //flexDirection: "column",
  //alignItems: "right",
  padding: "0 60px",
  width: "660px",
}));

const ShowTableCell = styled(TableCell)(({ theme }) => ({
  padding: "0px 0px 10px 0px",
  border: "0",
}));

export const ShowLabel = styled(InputLabel)(({ theme, title }) => ({
  color: "black",
  fontSize: "11px !important",
}));

const ShowInput = styled(TextField)(({ theme }) => ({
  paddingLeft: "12px",
  width: "100%",
  "& .MuiInputBase-root": {
    fontSize: "12px",
    "&:before, &:after": {
      borderBottom: "none !important",
    },
  },
}));

const EditItem = styled(Box)(({ theme }) => ({
  display: "grid",
  width: "350px",
  paddingBottom: "15px",
}));

const EditLabel = styled(InputLabel)(({ theme }) => ({
  color: "black",
  fontSize: "14px !important",
  paddingBottom: "5px",
}));
const ImageBox = styled(Box)(({ theme }) => ({
  display: "inline-block",
  width: "100px",
  height: "100px",
  margin: '5px',
  "& .galleryImg": {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
}));

const GalleryWrapper = styled(Box)(({ theme }) => ({
    display:"flex",
    width:"100%",
    maxWidth:"500px",
    flexWrap:"wrap"
  }));

export const EditTextField = styled(TextField)(({ theme }) => ({
  margin: "0",
  "& .MuiInputBase-input": {
    fontSize: "16px",
    padding: "10px 14px",
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
}));

const EditButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#f9f5ff",
  margin: "10px 20px 0 0",
  color: "#6941c6",
  maxWidth: "140px",
  "& .MuiTypography-root": {
    fontWeight: "500",
    fontSize: "13px",
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  marginTop: "10px",
  padding: "8px 12px",
  maxWidth: "120px",
  "& .MuiTypography-root": {
    fontWeight: "500",
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  marginTop: "10px",
  padding: "8px 12px",
  maxWidth: "160px",
  color: "white",
  backgroundColor: "#7f56d9",
  "& .MuiTypography-root": {
    fontWeight: "500",
  },
  "&:hover": {
    backgroundColor: "#9470e4",
    boxShadow: "none",
  },
}));

export interface ExtendedIResourceComponentsProps
  extends IResourceComponentsProps {
  rows?: any;
  ids?: any;
}
export const BlogPostEdit: React.FC<ExtendedIResourceComponentsProps> = ({
  rows,
  ids,
}) => {
  const { setWarnWhen } = useWarnAboutChange();
  const go = useGo();
  const {
    saveButtonProps,
    refineCore: { queryResult, onFinish },
    register,
    control,
    formState: { errors, isLoading },
    handleSubmit,
    reset,
  } = useForm({
    refineCoreProps: {
      redirect: false,
    },
  });
  /* const { autocompleteProps: categoryAutocompleteProps } = useAutocomplete({
        resource: "categories",
        defaultValue: blogPostsData?.category?.id,
    }); */
  const receiptsData = queryResult?.data?.data;

  // States
  const [isEditMode, setIsEditMode] = useState(false);
  const [nextId, setNextId] = useState(undefined);
  const [prevId, setPrevId] = useState(undefined);
  const [lineItems, setLineItems] = useState([]);
  const [currentImage, setCurrentImage] = useState('');
  const [ocrResultPaymentMethod,setOcrResultPaymentMethod] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  // console.log('Mastercard ending with 2201',register("paymentMethod", {}));
  interface IPaymentMethods {
    id: number;
    name: string;
    status: number;
  }
  const { data:paymentMethods, isLoading:paymentListLoging, isError } = useList<IPaymentMethods, HttpError>({
    resource: "paymentMethods",
  });
  const paymentList = paymentMethods?.data?.map(item=>item.name) ?? [];
  console.log('paymentList',paymentList);

  const handleApprove = (data: FieldValues) => {
    onFinish({ status: "Approved" }).then((res) => {
      setIsEditMode(false);
      if (nextId) {
        go({ to: `/receipt/edit/${nextId}` });
      }
    });
  };

  const handleReject = (data: FieldValues) => {
    onFinish({ status: "Rejected" }).then((res) => {
      setIsEditMode(false);
      if (nextId) {
        go({ to: `/receipt/edit/${nextId}` });
      }
    });
  };

  const handleSave = (data: FieldValues) => {
    delete data.campaignId;
    delete data.campaign;
    console.log('handleSave:',data)
    // use datagrid value as ocrResult line items
    data.ocrResult.items = lineItems
    onFinish(data).then((res) => {
      setIsEditMode(false);
    });
  };

  const handleImageClick = (imageUrl:string)=>{
    setCurrentImage(imageUrl);
  }


  const showTableRows = [
    { label: "Merchant Name", fieldName: "merchantName" },
    { label: "Receipt No.", fieldName: "No" },
    { label: "Line Items", fieldName: "ocrResult" },
    { label: "Amount", fieldName: "amount" },
    { label: "Payment Date", fieldName: "paymentDate" },
    { label: "Submission Date", fieldName: "createdAt" },
    { label: "Last Updated", fieldName: "updatedAt" },
    { label: "User Name", fieldName: "name" },
    { label: "User Email", fieldName: "email" },
    { label: "Status", fieldName: "status" },
    { label: "Campaign", fieldName: "campaign.name" },
    { label: "Remark", fieldName: "remarks", defaultValue: "-" },
    { label: "Discount", fieldName: "discount" },
    { label: "Payment Method", fieldName: "paymentMethod" },
    { label: "Shopping Type", fieldName: "shoppingType" },
    { label: "Source ", fieldName: "source" },
  ];

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <>
        <Box sx={{ width: "40px", marginLeft: "auto", padding: "20px 5px" ,position: 'absolute',right: "-40px" }}>
          <ZoomInOutlinedIcon
            sx={{
              width: "100%",
              cursor: "pointer",
              fontSize: "30px",
            }}
            onClick={() => zoomIn()}
          />
          <ZoomOutOutlinedIcon
            sx={{
              width: "100%",
              cursor: "pointer",
              fontSize: "30px",
            }}
            onClick={() => zoomOut()}
          />
          {/* <button style={{ width: "100%" }} onClick={() => resetTransform()}>
            Reset
          </button> */}
        </Box>
      </>
    );
  };

  return (
    <>
      <style>{css}</style>
      <TitleBox>
        <Typography variant='h1'>Receipt Details</Typography>
      </TitleBox>
      <Content>
        <EditLayout
          saveButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(handleSave),
          }}
          approveButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(handleApprove),
          }}
          rejectButtonProps={{
            ...saveButtonProps,
            onClick: handleSubmit(handleReject),
          }}
          isLoading={isLoading}
          rows={rows}
          ids={ids}
          status={receiptsData?.status}
          setIsEditMode={setIsEditMode}
          nextId={nextId}
          setNextId={setNextId}
          prevId={prevId}
          setPrevId={setPrevId}
        >
          <Stack direction='row'>
            <ReceiptPhotoContainer sx={{ position: 'relative',flexDirection: 'column' }}>
              
              <TransformWrapper initialScale={1}>
                <TransformComponent>
                  <ReceiptPhotoBox
                    url={currentImage?currentImage:receiptsData?.metaData && receiptsData?.metaData.images[0]?receiptsData?.metaData.images[0]:receiptsData?.image}
                    sx={{ width: "100%", height: "500px" }}
                  />
                </TransformComponent>
                <Controls />
              </TransformWrapper>
              <GalleryWrapper>
              { receiptsData && receiptsData.metaData ? receiptsData.metaData.images.map(
                (image:string,index:number)=>(
                  <ImageBox sx={{border:(index===0 && !currentImage) ||currentImage === image ? '2px solid blue': '1px solid gray'}}>
                    <img key={index.toString()} src={image} className='galleryImg'
                      onClick={()=>handleImageClick(image)}
                    />
                  </ImageBox>
                )
              ):(
              <ImageBox sx={{border:(!currentImage) ||currentImage === receiptsData?.image ? '2px solid blue': '1px solid gray'}}>
                <img src={receiptsData?.image} className='galleryImg'
                  onClick={()=>handleImageClick(receiptsData?.image)}
                />
              </ImageBox>
            )
              }
            </GalleryWrapper>
            </ReceiptPhotoContainer>

            


            <ReceiptDetailsContainer>
              {isEditMode ? (
                <>
                  <EditItem>
                    <EditLabel>Merchant Name</EditLabel>
                    <EditTextField
                      {...register("merchantName", {})}
                      error={!!(errors as any)?.merchantName}
                      helperText={(errors as any)?.merchantName?.message}
                      margin='normal'
                      type='text'
                      name='merchantName'
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Receipt No.</EditLabel>
                    <EditTextField
                      {...register("No", {
                        /* required: "This field is required", */
                      })}
                      type='text'
                      error={!!(errors as any)?.No}
                      helperText={(errors as any)?.No?.message}
                      margin='normal'
                      InputLabelProps={{ shrink: true }}
                      name='No'
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Line Items</EditLabel>
                    <Controller
                      control={control}
                      name='ocrResult'
                      render={({ field }) => {
                        if(field.value && field.value.payment_method && field.value.payment_method !=null ){
                          setOcrResultPaymentMethod(field.value.payment_method);
                        }
                        if (
                          !field.value ||
                          field.value.items === null ||
                          !field.value.items
                        ) {
                          // console.log('field', field.value);
                          return <p>-</p>;
                        }
                        return (
                          <CustomTable
                            rows={field.value.items}
                            isEditing={isEditMode}
                            onChange={(data)=> {
                              // console.log('after changing',data)
                              setLineItems(data)
                            }}
                          />
                        );
                      }}
                    />
                  </EditItem>
                  {/* <Controller
                                control={control}
                                name="category"
                                // eslint-disable-next-line
                                defaultValue={null as any}
                                render={({ field }) => (
                                    <Autocomplete
                                        {...categoryAutocompleteProps}
                                        {...field}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                        }}
                                        getOptionLabel={(item) => {
                                            return (
                                                categoryAutocompleteProps?.options?.find(
                                                    (p) =>
                                                        p?.id?.toString() ===
                                                        item?.id?.toString(),
                                                )?.title ?? ""
                                            );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            value === undefined ||
                                            option?.id?.toString() === value?.id?.toString()
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Amount"
                                                margin="normal"
                                                variant="outlined"
                                                error={!!(errors as any)?.category?.id}
                                                helperText={
                                                    (errors as any)?.category?.id?.message
                                                }
                                                required
                                            />
                                        )}
                                    />
                                )}
                            />  */}
                  {/* <EditItem>
                                <EditLabel>Status</EditLabel>
                                <EditTextField
                                    {...register("status", {
                                        required: "This field is required", 
                                    })}
                                    error={!!(errors as any)?.status}
                                    helperText={(errors as any)?.status?.message}
                                    margin="normal"
                                    type="text"
                                    name="status"                          
                                />
                            </EditItem> */}
                  <EditItem>
                    <EditLabel>Amount</EditLabel>
                    <EditTextField
                      {...register("amount", {
                        /* required: "This field is required", */
                      })}
                      error={!!(errors as any)?.amount}
                      helperText={(errors as any)?.amount?.message}
                      margin='normal'
                      type='text'
                      name='amount'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            sx={{
                              "& .MuiTypography-root": { fontSize: "16px" },
                            }}
                          >
                            HK${" "}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Payment Date</EditLabel>
                    <Controller
                      control={control}
                      name='paymentDate'
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            ampm={false}
                            onChange={(date) => field.onChange(date)}
                            value={dayjs(field.value)}
                            sx={{
                              "& .MuiInputBase-root": {
                                fontSize: "16px",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Remarks</EditLabel>
                    <EditTextField
                      {...register("remarks", {})}
                      error={!!(errors as any)?.remarks}
                      helperText={(errors as any)?.remarks?.message}
                      margin='normal'
                      name='remarks'
                      multiline
                      rows={3}
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Discount</EditLabel>
                    <EditTextField
                      {...register("discount", {})}
                      error={!!(errors as any)?.discount}
                      helperText={(errors as any)?.discount?.message}
                      margin="normal"
                      name="discount"
                      rows={3}
                      placeholder="Enter discount"
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Payment Method</EditLabel>
                    <Controller
                      name="paymentMethod"
                      // name='ocrResult'
                      control={control}
                      render={({ field }) => {
                        console.log('paymentMethodField',field);
                        return(
                        // <Select
                        //   {...field}
                        //   labelId="payment-method-select-label"
                        //   id="payment-method-select"
                        //   placeholder="Select payment method"
                        // >
                        //   <MenuItem value={"Visa"}>Visa</MenuItem>
                        //   <MenuItem value={"Master"}>Master</MenuItem>
                        //   <MenuItem value={"Cash"}>Cash</MenuItem>
                        //   <MenuItem value={"Octopus"}>Octopus</MenuItem>
                        //   <MenuItem value={"Alipay"}>Alipay</MenuItem>
                        //   <MenuItem value={"WeChat Pay"}>WeChat Pay</MenuItem>
                        //   <MenuItem value={"Payme"}>Payme</MenuItem>
                        //   <MenuItem value={"AMEX"}>AMEX</MenuItem>
                        //   <MenuItem value={"EPS"}>EPS</MenuItem>
                        // </Select>
                        <Autocomplete
                          disablePortal
                          value = {field.value || ocrResultPaymentMethod}
                          options={paymentList}
                          onChange = {(event,data) => {
                            // console.log('Autocomplete:',paymentMethod,paymentList,event,data);
                            if(data!=null){
                              field.onChange(data);
                              setPaymentMethod(data);
                            }
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    }
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Shopping Type</EditLabel>
                    <Controller
                      name="shoppingType"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="shopping-type-select-label"
                          id="shopping-type-select"
                          placeholder="Select shopping type status"
                        >
                          <MenuItem value={"Online"}>Online</MenuItem>
                          <MenuItem value={"Offline"}>Offline</MenuItem>
                        </Select>
                      )}
                    />
                  </EditItem>
                  <EditItem>
                    <EditLabel>Source</EditLabel>
                    <Controller
                      name="source" // match key in GET /receipt/:id
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="source-select-label"
                          id="source-select"
                          placeholder="Select Source"
                        >
                          <MenuItem value={"Authorized"}>Authorized</MenuItem>
                          <MenuItem value={"Official"}>Official</MenuItem>
                          <MenuItem value={"Parallel Import"}>
                            Parallel Import
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </EditItem>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <CancelButton
                      variant='outlined'
                      onClick={async () => {
                        setIsEditMode(false);
                        reset({ ...queryResult?.data?.data });
                        setWarnWhen(false);
                      }}
                    >
                      <Typography>Cancel</Typography>
                    </CancelButton>
                    <SaveButton
                      variant='outlined'
                      {...saveButtonProps}
                      onClick={handleSubmit(handleSave)}
                    >
                      <Typography>Save</Typography>
                    </SaveButton>
                  </Box>
                </>
              ) : (
                <>
                  <Table key='showTable'>
                    <TableBody>
                      {showTableRows.map((row) => 
                        row.label!='Line Items'?
                        (
                        <TableRow key={row.label}>
                          <ShowTableCell sx={{ width: "0" }}>
                            <ShowLabel>{row.label}</ShowLabel>
                          </ShowTableCell>
                          <ShowTableCell sx={{ width: "100%" }}>
                            {(() => {
                              if (
                                row.fieldName === "paymentDate" ||
                                row.fieldName === "updatedAt" ||
                                row.fieldName === "createdAt"
                              ) {
                                return (
                                  <Controller
                                    control={control}
                                    name={row.fieldName}
                                    render={({ field }) => (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <ShowInput
                                          variant='standard'
                                          inputProps={{ disabled: true }}
                                          type='text'
                                          name={row.fieldName}
                                          value={dayjs(field.value).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                          )}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  />
                                );
                              } else if (row.fieldName === "status") {
                                return (
                                  <Controller
                                    control={control}
                                    name={row.fieldName}
                                    render={({ field }) => {
                                      const { textColor, backgroundColor } =
                                        statusColorMapping[field.value] ||
                                        statusColorMapping["Default"];
                                      return (
                                        <StatusChip
                                          label={field.value}
                                          textcolor={textColor}
                                          backgroundcolor={backgroundColor}
                                          icon={
                                            <FiberManualRecordIcon
                                              style={{
                                                fontSize: "8px",
                                                color: textColor,
                                              }}
                                            />
                                          }
                                          sx={{
                                            fontSize: "11px",
                                            padding: "2px",
                                            height: "unset",
                                            width: "fit-content",
                                          }}
                                        />
                                      );
                                    }}
                                  />
                                );
                              // } else if (row.fieldName === "ocrResult") {
                              //   return (
                              //     <Controller
                              //       control={control}
                              //       name='ocrResult'
                              //       render={({ field }) => {
                              //         if(field.value && field.value.payment_method && field.value.payment_method !=null ){
                              //           setOcrResultPaymentMethod(field.value.payment_method);
                              //           console.log('ShowTable - setOcrResultPaymentMethod',ocrResultPaymentMethod);
                              //         }
                              //         if (
                              //           !field.value || 
                              //           (Array.isArray(field.value.items) &&
                              //             field.value.items?.length === 0) ||
                              //           field.value.items === null ||
                              //           Object.keys(field.value).length === 0
                              //         ) {
                              //           return <p>-</p>;
                              //         }
                              //         return (
                              //           <CustomTable
                              //             rows={field.value.items}
                              //             isEditing={isEditMode}
                              //           />
                              //         );
                              //       }}
                              //     />
                              //   );
                              } else if(row.fieldName === "paymentMethod") {
                                  console.log("ShowPaymentMethod:",register(
                                    row.fieldName || "nullish fallback",
                                    {}
                                  ));
                                  return(
                                    <Controller
                                      control={control}
                                      name={row.fieldName}
                                      render={({ field }) => (
                                          <ShowInput
                                            variant='standard'
                                            inputProps={{ disabled: true }}
                                            type='text'
                                            name={row.fieldName}
                                            value={field.value||ocrResultPaymentMethod}
                                          />
                                      )}
                                    />
                                  )
                              } else {                                
                                return (
                                  <ShowInput
                                    {...register(
                                      row.fieldName || "nullish fallback",
                                      {}
                                    )}
                                    variant='standard'
                                    inputProps={{ disabled: true }}
                                    type='text'
                                    name={row.fieldName}
                                    defaultValue={row.defaultValue}
                                  />
                                );
                              }
                            })()}
                          </ShowTableCell>
                        </TableRow>
                      ):
                      ( <TableRow>
                          <TableCell colSpan={2} sx={{padding:'0px'}}>
                            <Table>
                              <TableRow key={row.label}>
                                <ShowTableCell colSpan={2} sx={{ width: "0" }}>
                                  <ShowLabel>{row.label}</ShowLabel>
                                </ShowTableCell>
                                </TableRow>
                                <TableRow>
                                <ShowTableCell colSpan={2} sx={{ width: "100%"}}>
                                  {(()=>{
                                    return (
                                      <Controller
                                        control={control}
                                        name='ocrResult'
                                        render={({ field }) => {
                                          if(field.value && field.value.payment_method && field.value.payment_method !=null ){
                                                      setOcrResultPaymentMethod(field.value.payment_method);
                                                      // console.log('ShowTable - setOcrResultPaymentMethod',ocrResultPaymentMethod);
                                              }
                                          if (
                                            !field.value || 
                                            (Array.isArray(field.value.items) &&
                                              field.value.items?.length === 0) ||
                                            field.value.items === null ||
                                            Object.keys(field.value).length === 0
                                          ) {
                                            return <p>-</p>;
                                          }
                                          return (
                                            <CustomTable
                                              rows={field.value.items}
                                              isEditing={isEditMode}
                                            />
                                          );
                                        }}
                                      />
                                    );
                                  })()}
                                    </ShowTableCell>
                                
                              </TableRow>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )
                      
                    )}
                    </TableBody>
                  </Table>
                  <EditButton
                    onClick={() => setIsEditMode(true)}
                    startIcon={<EditOutlinedIcon />}
                  >
                    <Typography>Edit Details</Typography>
                  </EditButton>
                </>
              )}
            </ReceiptDetailsContainer>
          </Stack>
        </EditLayout>
      </Content>
    </>
  );
};

/*
    DatePicker component is not included in "@refinedev/mui" package.
    To use a <DatePicker> component, you can follow the official documentation for Material UI.

    Docs: https://mui.com/x/react-date-pickers/date-picker/#basic-usage
*/
