import { PropsWithChildren } from "react";
import { Header } from "../myHeader";
import Box from "lib/ui/mui/Box";
import styled from "styled-components";

const Main = styled(Box)(({ theme }) => ({
  mt: "75px",
  padding: "50px 100px",
  backgroundColor: theme.palette.background.main,
}));

/* const TitleBox = styled(Box)(({ theme }) => ({
  paddingBottom: "30px",
})); */

/* const Content = styled(Card)(({ theme }) => ({})); */

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  /* const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const onToggleDrawer = () => {
    setIsDrawerOpen(isDrawerOpen ? false : true);
    console.log(isDrawerOpen);
  } */

  return (
    <>
      <Header />
      <Main sx={{ mt: "75px", padding: "50px 100px" }}>
        {/* <TitleBox>
          <Typography variant="h1">Receipts</Typography>
        </TitleBox>
        <Content>{children}</Content> */}
        {children}
      </Main>
    </>
  );
};
/* <Box className="layout">
      <Breadcrumb onToggleDrawer={onToggleDrawer}/>
      <div className="container">
        <Menu isDrawerOpen={isDrawerOpen}/>
        <div className="content">
          {children}
        </div>
      </div>
    </Box> */
