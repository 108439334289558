//import { CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import theme from "./theme/theme";
import { Refine } from "@refinedev/core";
import {
  ErrorComponent,
  //ThemedLayoutV2,
  RefineThemes,
  notificationProvider,
  RefineSnackbarProvider,
} from "@refinedev/mui";
import routerBindings, {
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
//import dataProvider from "@refinedev/simple-rest";
import { dataProvider } from "data-provider";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import { Layout } from "./components/layout";
import "./App.css";

// import { MuiInferencer } from "@refinedev/inferencer/mui";
import { BlogPostEdit } from "pages/blog-posts/edit";
import { BlogPostList } from "pages/blog-posts/list";

const App: React.FC = () => {
  const [ids, setIds] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [campaignFilter, setCampaignFilter] = useState("");
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          {/* <ThemeProvider theme={RefineThemes.Blue}> */}
          <CssBaseline />
          {/* <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} /> */}
          <RefineSnackbarProvider>
            <BrowserRouter>
              <Refine
                routerProvider={routerBindings}
                dataProvider={dataProvider(
                  process.env.REACT_APP_DATAPROVIDER_URL || ""
                )}
                notificationProvider={notificationProvider}
                resources={[
                  {
                    name: "receipts",
                    list: "/receipts",
                  },
                  {
                    name: "receipt",
                    edit: "/receipt/edit/:id",
                  },
                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                }}
              >
                <Routes>
                  <Route
                    element={
                      <Layout>
                        <Outlet />
                      </Layout>
                    }
                  >
                    <Route
                      index
                      element={<NavigateToResource resource="receipts" />}
                    />

                    <Route path="receipts">
                      <Route
                        index
                        element={
                          <BlogPostList
                            setIds={setIds}
                            searchFilter={searchFilter}
                            setSearchFilter={setSearchFilter}
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}
                            campaignFilter={campaignFilter}
                            setCampaignFilter={setCampaignFilter}
                            pageCurrent={pageCurrent}
                            setPageCurrent={setPageCurrent}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            sortField={sortField}
                            setSortField={setSortField}
                            sortOrder={sortOrder}
                            setSortOrder={setSortOrder}
                          />
                        }
                      />
                    </Route>
                    <Route path="receipt">
                      <Route
                        path="edit/:id"
                        element={<BlogPostEdit ids={ids} />}
                      />
                    </Route>

                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                </Routes>
                <UnsavedChangesNotifier />
              </Refine>
            </BrowserRouter>
          </RefineSnackbarProvider>
          {/*  </ThemeProvider> */}
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
