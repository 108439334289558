import React, { useEffect, useState } from "react";

import {
  useMutationMode,
  useNavigation,
  useTranslate,
  useUserFriendlyName,
  useRefineContext,
  useToPath,
  useResource,
  useRouterType,
  useBack,
  useGo,
  BaseKey,
} from "@refinedev/core";
import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "../../lib/ui/mui/Button";

import { EditButton } from "components/editButton";

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  RefreshButton,
  SaveButton,
  RefreshButtonProps,
  SaveButtonProps,
} from "@refinedev/mui";
import { EditProps } from "@refinedev/mui";
import { RefinePageHeaderClassNames } from "@refinedev/ui-types";
import { C } from "ts-toolbelt";

const BackButton = styled(Button)(({ theme }) => ({
  padding: "8px 16px",
  borderColor: "rgba(0, 0, 0, 0.12)",
  fontWeight: "500",
  "& .MuiTypography-root": {
    fontWeight: "bold",
  },
}));

const RejectButton = styled(Button)(({ theme }) => ({
  padding: "8px 16px",
  color: "#b42419",
  backgroundColor: "#fef3f2",
  fontWeight: "500",
  "&:hover": {
    backgroundColor: "#ebdcdb",
    boxShadow: "none",
  },
}));

const ApproveButton = styled(Button)(({ theme }) => ({
  padding: "8px 16px",
  color: "white",
  fontWeight: "500",
  backgroundColor: "#7f56d9",
  "& .MuiTypography-root": {
    fontWeight: "bold",
  },
  "&:hover": {
    backgroundColor: "#9470e4",
    boxShadow: "none",
  },
}));

/* const DeliverButton = styled(Button)(({ theme }) => ({
    padding: '8px 16px',
    color: 'white',
    fontWeight: '500',
    backgroundColor: '#7f56d9',
    '& .MuiTypography-root':{
        fontWeight: 'bold'
    },
    '&:hover': {
        backgroundColor: '#9470e4',
        boxShadow: 'none',
      },
})); */

/**
 * `<Edit>` provides us a layout for displaying the page.
 * It does not contain any logic but adds extra functionalities like a refresh button.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/basic-views/edit} for more details.
 */
type ExtendedEditProps = EditProps & {
  approveButtonProps: SaveButtonProps;
  rejectButtonProps: SaveButtonProps;
  /* deliverButtonProps: SaveButtonProps; */
  rows: any;
  ids: any;
  status: string;
  setIsEditMode: any;
  nextId: any;
  setNextId: any;
  prevId: any;
  setPrevId: any;
};

export const EditLayout: React.FC<ExtendedEditProps> = ({
  title,
  saveButtonProps: saveButtonPropsFromProps,
  approveButtonProps,
  rejectButtonProps,
  /* deliverButtonProps, */
  ids,
  status,
  setIsEditMode,
  nextId,
  setNextId,
  prevId,
  setPrevId,
  /* rejectButtonProps,
    backButtonProps, */
  mutationMode: mutationModeProp,
  recordItemId,
  children,
  resource: resourceFromProps,
  isLoading = false,
  dataProviderName,
  wrapperProps,
  headerProps,
  contentProps,
  headerButtonProps,
  headerButtons,
  footerButtonProps,
  footerButtons,
  goBack: goBackFromProps,
}) => {
  const translate = useTranslate();

  const routerType = useRouterType();
  const back = useBack();
  const go = useGo();
  const { goBack } = useNavigation();
  const getUserFriendlyName = useUserFriendlyName();

  const {
    resource,
    action,
    id: idFromParams,
    identifier,
  } = useResource(resourceFromProps);

  /* const [nextId, setNextId] = useState(undefined);
  const [prevId, setPrevId] = useState(undefined); */
  const [currentRow, setCurrentRow] = useState(undefined);

  const id = recordItemId ?? idFromParams;

  useEffect(() => {
    const currentIndex = ids.findIndex(
      (row: { id: BaseKey | undefined }) => Number(row?.id) === Number(id)
    );
    const previousIndex = currentIndex - 1;
    const nextIndex = currentIndex + 1;

    const pId = previousIndex >= 0 ? ids[previousIndex]?.id : undefined;
    const nId = nextIndex < ids.length ? ids[nextIndex]?.id : undefined;

    setCurrentRow(currentIndex + 1);
    setNextId(nId);
    setPrevId(pId);
    setIsEditMode(false);
  }, [ids, id, setNextId, setPrevId, setIsEditMode]);

  const refreshButtonProps: RefreshButtonProps = {
    ...(isLoading ? { disabled: true } : {}),
    resource: routerType === "legacy" ? resource?.route : identifier,
    recordItemId: id,
    dataProviderName,
  };

  const defaultHeaderButtons = (
    <Box>
      {/* <RefreshButton {...refreshButtonProps} /> */}
      <EditButton
        IconComponent={ArrowBackIcon}
        hideText
        disabled={prevId === undefined}
        recordItemId={prevId}
        //onClick={() => setIsEditMode(false)}
      />
      <EditButton
        IconComponent={ArrowForwardOutlinedIcon}
        hideText
        disabled={nextId === undefined}
        recordItemId={nextId}
        //onClick={() => setIsEditMode(false)}
      />
    </Box>
  );

  const saveButtonProps /* : SaveButtonProps */ = {
    ...(isLoading ? { disabled: true } : {}),
    ...saveButtonPropsFromProps,
  };

  const defaultFooterButtons = (
    <>
      <BackButton
        variant="outlined"
        onClick={() => {
          go({ to: "/receipts" });
        }}
      >
        Back
      </BackButton>
      {status !== "Approved" && status !== "Rejected" && (
        <RejectButton {...rejectButtonProps}>Reject</RejectButton>
      )}
      {status !== "Approved" && status !== "Rejected" && (
        <ApproveButton {...approveButtonProps}>Approve</ApproveButton>
      )}
    </>
  );

  return (
    <Card {...(wrapperProps ?? {})} sx={{ padding: "20px" }}>
      <CardHeader
        sx={{
          display: "flex",
          flexWrap: "wrap",
          ".MuiCardHeader-action": {
            margin: 0,
            alignSelf: "center",
          },
          borderBottom: 1,
          borderColor: "rgba(0, 0, 0, 0.12)",
          padding: "5px",
        }}
        title={
          title ?? (
            <Typography
              variant="h3"
              className={RefinePageHeaderClassNames.Title}
            >
              View Details
            </Typography>
          )
        }
        avatar={
          typeof goBackFromProps !== "undefined" ? (
            goBackFromProps
          ) : (
            <IconButton
              onClick={
                /* action !== "list" &&
                                typeof action !== "undefined"
                                    ? routerType === "legacy"
                                        ? goBack
                                        : back
                                    : undefined */
                () => {
                  go({ to: "/receipts" });
                }
              }
            >
              <ArrowBackIcon />
            </IconButton>
          )
        }
        action={
          <Box
            display="flex"
            alignItems="center"
            gap="10px"
            fontSize="13px"
            color=""
            {...(headerButtonProps ?? {})}
          >
            {currentRow} of {ids.length}
            {defaultHeaderButtons}
          </Box>
        }
        {...(headerProps ?? {})}
      />
      <CardContent
        {...(contentProps ?? {})}
        sx={{
          padding: "20px 0",
          borderBottom: 1,
          borderColor: "rgba(0, 0, 0, 0.12)",
        }}
      >
        {children}
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
          padding: "16px",
        }}
        {...(footerButtonProps ?? {})}
      >
        {defaultFooterButtons}
      </CardActions>
    </Card>
  );
};
