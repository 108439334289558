import React from "react";
import styled from "styled-components";
import MuiChip from "@mui/material/Chip";
import { sizing, spacing, palette, typography } from "@mui/system";

const Chip = styled(({ ...props }) => <MuiChip {...props} />)`
  ${spacing}
  ${sizing}
  ${palette}
  ${typography}
`;

Chip.defaultProps = {
  size: "small",
  // width:'3rem',
  fontWeight: "bolder",
  fontSize: "0.75rem",
  padding: "5px 16px",
  height: "auto",
};
export default Chip;
