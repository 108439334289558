import MuiButton from "@mui/material/Button";

export default MuiButton;

/* import React from "react";
import styled from "styled-components";
import MuiButton from "@mui/material/Button";
import { sizing, spacing, borders, palette } from "@mui/system";

const Button = styled(
  React.forwardRef(function Button(
    { width, maxWidth, minWidth, px, py, borderRadius, sx, ...props },
    ref
  ) {
    return (
      <MuiButton
        ref={ref}
        sx={{
          fontSize: {
            // xs: '0.75rem',
            md: "0.875rem",
          },
          lineHeight: "1.45",
          ...sx,
        }}
        {...props}
      />
    );
  })
)`
  ${spacing}
  ${borders}
  ${sizing}
  ${palette}
  ${(props) =>
    props.variant == "outlined" &&
    `
  &.MuiButtonBase-root {
    padding-top: calc(0.375rem - 1px);
    padding-bottom: calc(0.375rem - 1px);
  }
  `}
`;

Button.defaultProps = {
  // height: '2rem',
  px: "1rem",
  py: "0.375rem",
  borderRadius: "4px",
  color: "primary",
  variant: "contained",
  disableElevation: true,
};
export default Button; */
