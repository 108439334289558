import { createTheme, adaptV4Theme } from "@mui/material/styles";
//import { useTheme } from "@mui/styles";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1480,
  },
};

const defaultTheme = createTheme({ breakpoints });

// Create a theme instance.
const theme = createTheme({
  breakpoints,
  radius: {
    xs: "0.25rem",
    [defaultTheme.breakpoints.up("md")]: {
      xs: "0.25rem",
    },
  },
  border: {
    default: "1px solid rgba(0, 0, 0, 0.12)",
    dark: "1px solid rgba(0, 0, 0, 0.25)",
  },
  padding: {
    xs: "0.25rem",
    sm: "0.5rem",
    md: "1rem",
    lg: "1.5rem",
    xl: "2rem",
  },
  typography: {
    fontFamily: "Arial",
    h1: {
      fontWeight: "600",
      fontSize: "1.75rem",
      [defaultTheme.breakpoints.up("sm")]: {
        fontWeight: "400",
        fontSize: "2rem",
      },
    },
    h2: {
      fontWeight: "500",
      fontSize: "1.5rem",
      lineHeight: 1.25,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1.5rem",
      },
    },
    h3: {
      fontWeight: "500",
      fontSize: "1.25rem",
      lineHeight: 1.25,
      [defaultTheme.breakpoints.up("md")]: {
        fontWeight: "600",
        fontSize: "1.25rem",
      },
    },
    h4: {
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: 1.25,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
    h5: {
      fontWeight: "700",
      fontSize: "0.875rem",
      lineHeight: 1.25,
      [defaultTheme.breakpoints.up("md")]: {
        fontWeight: "600",
        fontSize: "0.9rem",
      },
    },
    subtitle1: {
      fontWeight: "400",
      fontSize: "0.75rem",
      lineHeight: 1,
      [defaultTheme.breakpoints.up("md")]: {
        fontSize: "0.8rem",
      },
    },
    subtitle2: {
      fontWeight: "700",
      fontSize: "0.875rem",
      [defaultTheme.breakpoints.up("md")]: {
        fontWeight: "600",
        fontSize: "0.8rem",
      },
    },
    body1: {
      fontWeight: "400",
      fontSize: "0.75rem",
      [defaultTheme.breakpoints.up("md")]: {
        fontWeight: "400",
        fontSize: "0.75rem",
      },
    },
    body2: {
      fontWeight: "400",
      fontSize: "0.75rem",
      [defaultTheme.breakpoints.up("md")]: {
        fontWeight: "400",
        fontSize: "0.85rem",
      },
    },
  },
  palette: {
    primary: {
      main: "#101828",
      // '#12465B',
      light: "#5b5bd6",
      // '#39AFD1',
    },
    secondary: {
      main: "#ffffff",
      // '#D6D3CA',
      light: "#e9eef4",
    },
    error: {
      main: "#FA5C62",
    },
    warning: {
      main: "#ffc107",
      lighter: "#ffe082",
      light: "#fff9c4",
      contrastText: "#fff",
    },
    success: {
      main: "#0ACF97",
    },
    background: {
      default: "#fff",
      main: "#f9fafb",
      light: "#f8f9fa",
      bg: "#3333cc",
      blackDrop: "rgba(0, 0, 0, 0.15)",
      whiteDrop: "rgba(255, 255, 255, 0.15)",
    },
    text: {
      primary: "#000000",
      // secondary: '#CAAF81',
    },
    chip: {
      label: "#f2f4f7",
      background: "#334054",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "*::-webkit-scrollbar": {
            background: "transparent",
            width: "0.5rem",
            height: "0.5rem",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.1)",
            borderRadius: "2rem",
          },
          WebkitPrintColorAdjust: "exact",
          input: {
            "&[type=number]": {
              // '-moz-appearance': 'textfield',
              MozAppearance: "textfield",
            },
            "&::-webkit-outer-spin-button": {
              // '-webkit-appearance': 'none',
              WebkitAppearance: "none",
              margin: 0,
            },
            "&::-webkit-inner-spin-button": {
              // '-webkit-appearance': 'none',
              WebkitAppearance: "none",
              margin: 0,
            },
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingBottom: "1rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.5rem",
        },
      },
    },
  },
});

export const useBreakpoint = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const width =
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs";
  const isMobile = ["xs", "sm"].includes(width);
  return {
    width,
    breakpoint: width,
    isMobile,
    padding: isMobile ? 3 : 6,
  };
};

export default theme;
